import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  constructor(public storage: Storage) { }
  /* Sets current store to local storage */
  async setCurrentStore(store) {
    return this.storage.set('currentStore', store);
  }
  /* Sets current client to local storage */
  async setCurrentClient(client) {
    return this.storage.set('currentClient', client);
  }
  /* Sets logged in user to local storage   */
  async setLoginUser(user) {
    return this.storage.set('loginUser', user);
    // localStorage.setItem('loginUser', JSON.stringify(user));
  }

  removeStorage(key) {
    return this.storage.remove(key);
  }
  /* Returns current store  */
  async getCurrentStore() {
    return this.storage.get('currentStore');
  }
  /* Returns current store  */
  async getCurrentClient() {
    return this.storage.get('currentClient');
  }
  /* Returns login user  */
  async getLoginUser() {
    return this.storage.get('loginUser');
    // return JSON.parse(localStorage.getItem('loginUser'));
  }
}
