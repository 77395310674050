export const UserTypeConstants = {
    SUPER_ADMIN: "Super Admin",
    STORE_ADMIN: "Store Admin",
    STORE_USER: "Store User",
    CUSTOMER_ADMIN : "Customer Admin",
    CUSTOMER_USER: "Customer User",
};
export const SubscriptionLevelConst = {
    LEVEL_1: "1",
    LEVEL_2: "2",
    LEVEL_3: "3",
    LEVEL_4: "4"
};
