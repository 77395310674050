import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController, NavParams } from '@ionic/angular';
import { AlertService } from '../../services/alert.service';
import { FirebaseService } from '../../services/firebase.service';
import { GetterSetterService } from '../../services/getter-setter.service';
import { LoadingService } from '../../services/loading.service';
import { ToastService } from '../../services/toast.service';
import { GlobalService } from '../../services/global.service';
import { UserTypeConstants } from '../../constants/UserTypeConstants';
import {  ObservableService } from '../../services/observable-service.service';
import { GetFireBaseDataService } from '../../services/get-fire-base-data.service';

@Component({
  selector: 'app-details',
  templateUrl: './details.page.html',
  styleUrls: ['./details.page.scss'],
})
export class DetailsPage implements OnInit {

/* Displays data according to viewed item */
  public itemDetails: any;
/* Displays page name */
  public pageName: any;
/* Handles page name for translation  */
  public translatePageName: any;
  /* Display store id */
  public storeId: any;
  /* Display store product lists */
  public products: any = [];
  /* Login user DOC_Id */
  public userId: any;
  /* CurrentHistoryDetails */
  public currentUserHistoryDetails: any = [];
  /* Details of product that user want to add again */
  public currentHistoryData: any = [];
  /* Display Edit button on list */
  @Input() isEdit: boolean = false;
  /* Display Delete button on list */
  @Input() isDelete: boolean = false;
  /* user store Subscription Level */
  public storeSubscriptionLevel: number;
  /* Displays Super Admin as constant string  */
  public superAdminConst: string;
  /* Logged in user type */
  public loginUserType = "";
  /* Display details of product */
  @Input() isDetail: boolean = false;
  public loginUserDetails: any = [];

  constructor(public navParams: NavParams,
    private modalController: ModalController,
    private getterSetterService: GetterSetterService,
    public router: Router,
    private globalService: GlobalService,
    private firebaseService: FirebaseService,
    private alertService: AlertService,
    private toastService: ToastService,
    private loadingService: LoadingService,
    private observableService: ObservableService,
    private getfirebasedataservice: GetFireBaseDataService) {
    this.pageName = this.navParams.get('pageName');
    this.itemDetails = this.getterSetterService.getTenantItemData();
    console.log(this.itemDetails);
    this.translatePageText();
  }

  ionViewWillEnter() {
    let loginUser = this.globalService.getLoginUser().then((loginUser: any) => {
      this.loginUserDetails = loginUser;
    this.superAdminConst = UserTypeConstants.SUPER_ADMIN;
    this.loginUserType = loginUser.USER_TYPE;
    if (this.loginUserType != this.superAdminConst) {
      this.storeId  = loginUser.STORE_ID;
      this.getUserStoreProfile(this.storeId);
    }
  });
  }

  getUserStoreProfile(storeId) {
    this.firebaseService.getUserDataFromFirestore('stores', storeId).then(storeData => {
      let storeAllData = storeData.data();
      this.storeSubscriptionLevel = storeAllData.SUBSCRIPTION_LEVEL;
    });
  }

  /* Performs translate page name functionality */
  translatePageText() {
    if (this.pageName == 'users') {
      this.translatePageName = "ADMIN.USERS";
    } else if (this.pageName == 'store') {
      this.translatePageName = "ADMIN.STORE";
    } else if (this.pageName == 'client') {
      this.translatePageName = "ADMIN.CLIENTS";
    } else if (this.pageName == 'history') {
      this.translatePageName = "ADMIN.PREVIOUS_RENTALS";
    } else if (this.pageName == 'orders') {
      this.translatePageName = "ADMIN.CURRENT_RENTALS";
    } else if (this.pageName == 'notifications') {
      this.translatePageName = "ADMIN.NOTIFICATION";
    } else if (this.pageName == 'products') {
      this.translatePageName = "ADMIN.PRODUCT";
    }
  }
  ngOnInit() {
  }
  onIconClick(data) {
    this.currentHistoryData = data;
    this.userId = this.loginUserDetails.id;
    this.storeId  = this.loginUserDetails.STORE_ID;
    this.getAllStoreProducts(this.storeId);
  }
  /* Get all store products */
  async getAllStoreProducts(storeId) {
    this.products = [];
    this.products = await this.getfirebasedataservice.getAllStoreProducts(storeId);
        this.products.forEach(element => {
          this.currentUserHistoryDetails = element;
          this.currentUserHistoryDetails.USER_DOC_ID = this.userId;
          this.currentUserHistoryDetails.NO_OF_PRODUCT = 1;
          if (this.currentHistoryData.ITEM_TYPE == element.ITEM_TYPE) {
            this.firebaseService.addDataToFirestore('cart', this.currentUserHistoryDetails).then(res => {
              this.toastService.presentToast("POPOVERS.ITEM_ADD_TO_CART");
              this.navigateToBack();
            }).catch(error => {
              console.log(error);
              this.toastService.presentErrorToast("POPOVERS.ADD_TO_CART_ERR");
            });
          }
        });
  }
   /* Navigates to previous page */
  navigateToBack() {
    this.modalController.dismiss({ reLoad: true });
  }
  /* Performs product details functionality */
  onDetail(itemDetails) {
    this.modalController.dismiss({ reLoad: false });
    this.observableService.setProduct(itemDetails);
    this.router.navigate(['/app/dashboard/product-configuration']);
  }
  /* Performs edit functionality */
  onEdit(itemDetails) {
    this.modalController.dismiss({ reLoad: false });
    if (this.pageName == 'users') {
      this.router.navigate(['/app/dashboard/new-user'], { queryParams: { "backPath": this.router.url, "isNewUser": 'false', user: JSON.stringify(itemDetails) } });
    } else if (this.pageName == 'store') {
      this.router.navigate(['/app/dashboard/create-store'], { queryParams: { isCreateStore: 'false', 'storeData': JSON.stringify(itemDetails)} });
    } else if (this.pageName == 'client') {
      this.router.navigate(['/app/dashboard/create-client'], { queryParams: { isCreateClient: 'false', 'clientData': JSON.stringify(itemDetails)} });
    }
  }
  /* Performs delete functionality */
  onDelete(itemDetails) {
    if (this.pageName == 'users') {
      this.alertService.presentAlert("POPOVERS.DELETE_CUSTOMER_USER").then(response => {
        this.loadingService.presentLoading();
        this.firebaseService.deleteDataFromFirestore('users', itemDetails.id).then(res => {
          this.firebaseService.deleteAuthUserFromFirestore(itemDetails.id).then(res => {
            this.loadingService.dismissLoading();
            this.toastService.presentToast("POPOVERS.USER_DELETED");
            this.modalController.dismiss({ reLoad: false, storeId : itemDetails.STORE_ID});
          }).catch(error => {
            this.loadingService.dismissLoading();
            this.toastService.presentErrToastDynamicMessage(itemDetails.USER_NAME , "POPOVERS.USER_NOT_DELETED");
            console.log('User not deleted' + error);
          });
        }).catch(error => {
          this.loadingService.dismissLoading();
          this.toastService.presentErrToastDynamicMessage(itemDetails.USER_NAME , "POPOVERS.USER_NOT_DELETED");
          console.log('User not deleted' + error);
        });
      });
    } else if (this.pageName == 'client') {
        this.alertService.presentAlert("POPOVERS.DELETE_CLIENT").then(response => {
            this.loadingService.presentLoading();
            this.firebaseService.deleteDataFromFirestore('clients', itemDetails.id).then(res => {
              this.firebaseService.getClientUserFromFirestore('users', itemDetails.id).then(snapshot => {
                if (snapshot.empty) {
                  console.log("no user in this client");
                } else {
                  snapshot.forEach(doc => {
                    this.firebaseService.deleteDataFromFirestore('users', doc.id).then(res => {
                      this.firebaseService.deleteAuthUserFromFirestore(doc.id).then(res => {
                      });
                    });
                  });
                }
                this.firebaseService.deleteClientImageFromStorage('/clientimages' + '/', itemDetails.CLIENT_IMG).then( response => {
                  this.loadingService.dismissLoading();
                  this.toastService.presentToastDynamicMessage(itemDetails.CLIENT_NAME , "POPOVERS.CLIENT_DELETED");
                  this.modalController.dismiss({ reLoad: false });
                });
              });
            });
        });
    } else if (this.pageName == 'store') {
      this.alertService.presentAlert("POPOVERS.DELETE_STORE").then(response => {
            this.loadingService.presentLoading();
            this.firebaseService.deleteDataFromFirestore('stores', itemDetails.id).then(res => {
              this.firebaseService.getStoreUserFromFirestore('users', itemDetails.id).then(snapshot => {
                if (snapshot.empty) {
                  console.log("no user in this store");
                } else {
                  snapshot.forEach(doc => {
                    this.firebaseService.deleteDataFromFirestore('users', doc.id).then(res => {
                      this.firebaseService.deleteAuthUserFromFirestore(doc.id).then(res => {
                      });
                    });
                  });
                }
                this.firebaseService.deleteFolderFromStorage('/' + itemDetails.FIREBASE_FILE_LOCATION).then(response => {
                  this.loadingService.dismissLoading();
                  this.toastService.presentToast("POPOVERS.STORE_DELETED");
                  this.modalController.dismiss({ reLoad: false });
                });
              });
          });
      });
    }
  }
}
