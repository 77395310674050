import { Injectable } from '@angular/core';
import { FirebaseService } from './firebase.service';

@Injectable({
  providedIn: 'root'
})
export class StoreServiceService {

  constructor(
    private firebaseService: FirebaseService,
  ) { }

  async getStoreDataById(storeId) {
    const storeData = await this.firebaseService.getUserDataFromFirestore('stores', storeId);
    let storeAllData = storeData.data();
    return storeAllData;
    // this.storeSubscriptionLevel = storeAllData.SUBSCRIPTION_LEVEL;
  }
}
