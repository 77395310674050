import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { IonSearchbar } from '@ionic/angular';


@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  searchTerm: string;
  notifications: any;
  @Input() focus:boolean;
  @Output() searchItems = new EventEmitter();
  @ViewChild('searchbar') searchbar:IonSearchbar;

  constructor() { }

  ngOnInit() {
    if(this.focus == true){
      setTimeout(() => {
        this.searchbar.setFocus();
      }, 400);
    }
  }
  filterItems() {
    this.searchItems.emit(this.searchTerm);
  }
}
