import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(public toastController: ToastController, private translate: TranslateService) { }
  /* Toast for display message */
  async presentToast(displayMessage) {
    const toast = await this.toastController.create({
      message: this.translateMessage(displayMessage),
      duration: 2000
    });
    toast.present();
  }
  /* Toast for display error message */
  async presentErrorToast(displayMessage) {
    const toast = await this.toastController.create({
      message: this.translateMessage(displayMessage),
      duration: 2000,
      color: 'danger',
    });
    toast.present();
  }
  /* Toast for display two messages */
  async presentErrToastDynamicMessage(displayMessageOne, displayMessageTwo) {
    const toast = await this.toastController.create({
      message: this.translateMessage(displayMessageOne) + this.translateMessage(displayMessageTwo),
      duration: 2000,
      color: 'danger',
    });
    toast.present();
  }
  /* Toast for display two error messages */
  async presentToastDynamicMessage(displayMessageOne, displayMessageTwo) {
    const toast = await this.toastController.create({
      message: this.translateMessage(displayMessageOne) + this.translateMessage(displayMessageTwo),
      duration: 2000
    });
    toast.present();
  }
  /* Translates the toast message */
  translateMessage(message) {
    let result: string;
    this.translate.get(message).subscribe((res: string) => {
      result = res;
    });
    return result;
  }
}
