import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from '../../services/global.service';
import { FirebaseService } from '../../services/firebase.service';
import { UserTypeConstants } from '../../constants/UserTypeConstants';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  /* Notification Count */
  public notificationCount: any;
  /* Login user Doc_id */
  public userId: any;
  /* Login userData */
  public userData: any = [];
  /* Display title of navbar */
  @Input() header: string;
  /* Display menu in navbar */
  @Input() isMenu: boolean = false;
  /* Display back button in navbar */
  @Input() isNavigateBack: boolean = false;
  /* Display search icon in navbar */
  @Input() isSearch: boolean = false;
  /* Display filter icon in navbar */
  @Input() isFilter: boolean = false;
  /* Display cart icon in navbar */
  @Input() isCart: boolean = false;
  /* Use for navigating on previous page */
  @Output() navigateToBack = new EventEmitter();
  /* Use for searching on page */
  @Output() onSearch = new EventEmitter();
  /* Use for filtering on page */
  @Output() onFilter = new EventEmitter();
  /* Display store id */
  public storeId: any;
  /* user store Subscription Level */
  public storeSubscriptionLevel: number;
  /* Displays Super Admin as constant string  */
  public superAdminConst: string;
  /* Logged in user type */
  public loginUserType = "";

  constructor(
    public router: Router,
    private globalService: GlobalService,
    private firebaseService: FirebaseService) {

     }

  ngOnInit() {
    let loginUser = this.globalService.getLoginUser().then((loginUser: any) => {
    if (loginUser != null) {
    this.superAdminConst = UserTypeConstants.SUPER_ADMIN;
    this.loginUserType = loginUser.USER_TYPE;
    this.notificationCount = loginUser.NOTIFICATION_UNREAD_COUNT;
    this.userId = loginUser.id;
    if (this.loginUserType != this.superAdminConst) {
      this.storeId  = loginUser.STORE_ID;
      this.getUserStoreProfile(this.storeId);
    }
    }
  });
  }
  getUserStoreProfile(storeId) {
    this.firebaseService.getUserDataFromFirestore('stores', storeId).then(storeData => {
      let storeAllData = storeData.data();
      this.storeSubscriptionLevel = storeAllData.SUBSCRIPTION_LEVEL;
    });
  }

  notificationsCount() {
    let loginUser = this.globalService.getLoginUser().then((loginUser: any) => {
    this.userData = loginUser;
    this.firebaseService.updateDataInFirestore('users', this.userId , {NOTIFICATION_UNREAD_COUNT: this.userData.NOTIFICATION_UNREAD_COUNT = 0}).then(async res => {
      this.userData.NOTIFICATION_UNREAD_COUNT = 0;
      this.notificationCount = this.userData.NOTIFICATION_UNREAD_COUNT;
      await this.globalService.setLoginUser(this.userData);
      this.router.navigate(['/notifications']);
    });
  });
  }
  /* Perform navigation on previous page */
  onNavigateToBackEmit() {
    this.navigateToBack.emit();
  }
  /* Perform search operation */
  onSearchEmit() {
    this.onSearch.emit();
  }
  /* Perform filter operation */
  onFilterEmit() {
    this.onFilter.emit();
  }
  /* Open cart page */
  navigateToCart() {
    this.router.navigateByUrl( '/cart-des');
  }
  /* Open product page */
  navigateToProduct() {
    this.router.navigateByUrl( '/store-des');
  }
}
