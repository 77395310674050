import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class TranslationService {

  constructor(
    private translate: TranslateService
    ) { }
  /* Returns the changed language */
  getDefaultLanguage() {
    let language = localStorage.getItem("LANGUAGE");
    localStorage.getItem("LANGUAGE");
    return language;
  }
  /* Sets selected language */
  setLanguage(setLang) {
    localStorage.setItem("LANGUAGE", setLang);
    this.translate.use(setLang);
  }
}
