import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
const routes: Routes = [
  { path: '', redirectTo: 'app/dashboard', pathMatch: 'full' },
  { path: 'auth/login', loadChildren: './login/login.module#LoginPageModule' },
  // { path: '', redirectTo: '/walkthrough', pathMatch: 'full' },
  // { path: 'walkthrough', loadChildren: './walkthrough/walkthrough.module#WalkthroughPageModule' },
  // { path: 'getting-started', loadChildren: './getting-started/getting-started.module#GettingStartedPageModule' },
  { path: 'auth/signup', loadChildren: './signup/signup.module#SignupPageModule' },
  { path: 'auth/forgot-password', loadChildren: './forgot-password/forgot-password.module#ForgotPasswordPageModule' },
  { path: 'app', loadChildren: './tabs/tabs.module#TabsPageModule' },
  { path: 'forms-filters', loadChildren: './forms/filters/forms-filters.module#FormsFiltersPageModule' },
  // { path: 'page-not-found', loadChildren: './page-not-found/page-not-found.module#PageNotFoundModule' },
  { path: 'showcase', loadChildren: './showcase/showcase.module#ShowcasePageModule' },  
  { path: 'app/dashboard', loadChildren: './dashboard/dashboard.module#DashboardPageModule' },
  { path: 'language-settings', loadChildren: './language-settings/language-settings.module#LanguageSettingsPageModule' },
  { path: 'create-store', loadChildren: './create-store/create-store.module#CreateStorePageModule' },
  { path: 'stores-home', loadChildren: './stores-home/stores-home.module#StoresHomePageModule' },  
  { path: 'new-user', loadChildren: './new-user/new-user.module#NewUserPageModule' },  
  { path: 'customer-users', loadChildren: './customer-users/customer-users.module#CustomerUsersPageModule' },
  { path: 'store-tenants', loadChildren: './store-tenants/store-tenants.module#StoreTenantsPageModule' },
  { path: 'history', loadChildren: './history/history.module#HistoryPageModule' },
  { path: 'details', loadChildren: './modals/details/details.module#DetailsPageModule' },
  { path: 'orders', loadChildren: './orders/orders.module#OrdersPageModule' },
  { path: 'store-admins-list', loadChildren: './store-admins-list/store-admins-list.module#StoreAdminsListPageModule' },
  { path: 'reset-password', loadChildren: './reset-password/reset-password.module#ResetPasswordPageModule' },
  { path: 'store-des', loadChildren: './store-des/store-des.module#StoreDesPageModule' },
  { path: 'cart-des', loadChildren: './cart-des/cart-des.module#CartDesPageModule' },
  { path: 'profile', loadChildren: './profile/profile.module#ProfilePageModule' },
  { path: 'store-users-list', loadChildren: './store-users-list/store-users-list.module#StoreUsersListPageModule' },
  { path: 'settings', loadChildren: './settings/settings.module#SettingsPageModule' },
  { path: 'clients', loadChildren: './clients/clients.module#ClientsPageModule' },
  { path: 'customer-admin-list', loadChildren: './customer-admin-list/customer-admin-list.module#CustomerAdminListPageModule' },
  { path: 'customer-user-list', loadChildren: './customer-user-list/customer-user-list.module#CustomerUserListPageModule' },
  { path: 'create-client', loadChildren: './create-client/create-client.module#CreateClientPageModule' },
  { path: 'events-news', loadChildren: './events-news/events-news.module#EventsNewsPageModule' },
  { path: 'create-events-news', loadChildren: './create-events-news/create-events-news.module#CreateEventsNewsPageModule' },
  { path: 'notifications', loadChildren: './notifications/notifications.module#NotificationsPageModule' },
  { path: 'store-contact', loadChildren: './store-contact/store-contact.module#StoreContactPageModule' },
  { path: 'contact-info', loadChildren: './contact-info/contact-info.module#ContactInfoPageModule' },
  { path: 'event-des', loadChildren: './event-des/event-des.module#EventDesPageModule' },
  { path: 'pending-orders', loadChildren: './pending-orders/pending-orders.module#PendingOrdersPageModule' },
  { path: 'order-des', loadChildren: './order-des/order-des.module#OrderDesPageModule' },
  { path: 'product-configuration', loadChildren: './product-configuration/product-configuration.module#ProductConfigurationPageModule' },
  { path: 'update-order', loadChildren: './update-order/update-order.module#UpdateOrderPageModule' },


  // { path: '**', redirectTo: 'page-not-found' },
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
