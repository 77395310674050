import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GetterSetterService {

 /* Stores tenant item data */
 public tenantItemData: any;
 /* Stores user type data */
 public userType: any;

  constructor() { }

  /* Sets tenant item data */
  setTenantItemData(itemData) {
    this.tenantItemData = itemData;
  }
  /* Returns tenant item data */
  getTenantItemData() {
    return this.tenantItemData;
  }
  /* Sets user type */
  setUserType(userType) {
    this.userType = userType;
  }
  /* Returns user type */
  getUserType() {
    return this.userType;
  }
}
