import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFirestore } from 'angularfire2/firestore';
import { AngularFireStorage, AngularFireStorageReference } from 'angularfire2/storage';
import * as firebase from 'firebase';
const URL = "https://us-central1-winhyra-dev.cloudfunctions.net";
@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  constructor(
    private db: AngularFirestore,
    private afStorage: AngularFireStorage,
    public httpClient: HttpClient,
    public fireAuth: AngularFireAuth,
  ) { }
  getDataFromFirestore(collectionName) {
    return this.db.collection(collectionName).snapshotChanges();
  }
  getUserTypeByClientIdDataFromFirestore(collectionName, userType, storeId, clientId) {
    return this.db.collection<any>(collectionName, ref =>
    ref.where('USER_TYPE', '==', userType).where('CLIENT_ID', '==', clientId).where('STORE_ID', '==', storeId)).get().toPromise();
  }
  getUserTypeDataFromFirestore(collectionName, userType, storeId) {
    return this.db.collection<any>(collectionName, ref =>
    ref.where('USER_TYPE', '==', userType).where('STORE_ID', '==', storeId)).get().toPromise();
  }
  // Customer users filter
  getCustomerUserDataFromFirestore(collectionName, userType, storeId, clientId) {
    return this.db.collection<any>(collectionName, ref =>
    ref.where('USER_TYPE', '==', userType).where('STORE_ID', '==', storeId).where('CLIENT_DOC_ID', '==', clientId)).get().toPromise();
  }
  // Get Store users
  getStoreUserFromFirestore(collectionName, storeId) {
    return this.db.collection<any>(collectionName, ref =>
    ref.where('STORE_ID', '==', storeId)).get().toPromise();
  }
  // Get Client users
  getClientUserFromFirestore(collectionName, clientId) {
    return this.db.collection<any>(collectionName, ref =>
    ref.where('CLIENT_DOC_ID', '==', clientId)).get().toPromise();
   }
  getUserDataFromFirestore(collectionName, uid) {
    if (uid) {
      return this.db.collection<any>(collectionName).doc(uid).get().toPromise();
    }
  }
// Get User Data using PhoneNumber
  getUserDataFromPhoneNo(collectionName, phoneNo){
    if(phoneNo) {
      return this.db.collection<any>(collectionName, ref =>
      ref.where('PHONE_NO', '==', phoneNo)).get().toPromise();
    }
  }
//Check Email ID Exists or not
checkEmailIDInFireStore(collectionName, email) {
  if(email) {
    return this.db.collection<any>(collectionName, ref =>
    ref.where('EMAIL_ID', '==', email)).get().toPromise();

  }
} 
// Check Phone Number Exists or not
checkCountryCodePhoneNoInFireStore(collectionName, countryCode, phoneNo){
  if(phoneNo) {
    return this.db.collection<any>(collectionName, ref =>
    ref.where('COUNTRY_CODE', '==', countryCode).where('PHONE_NO', '==', phoneNo)).get().toPromise();
  }
}
//Check Client exists or not
checkClientName(collectionName, clientName) {
  if(clientName) {
    return this.db.collection<any>(collectionName, ref =>
    ref.where('CLIENT_NAME', '==', clientName)).get().toPromise();
  }
}

  getStoreSubCollection(collectionName, uid, subCollection) {
    return this.db.collection<any>(collectionName).doc(uid).collection(subCollection).get().toPromise();
  }
  getCustUserDataFromFirestore(collectionName, userType, storeId, AdminName) {
    return this.db.collection<any>(collectionName, ref =>
    ref.where('USER_TYPE', '==', userType).where('STORE_ID', '==', storeId).where('ADMIN_NAME', '==', AdminName)).get().toPromise();
  }
  getPromiseDataFromFirestore(collectionName) {
   return this.db.collection(collectionName).get().toPromise();
  }
  // To get Clients by stores
  getClientFromFirestore(collectionName, storeId) {
    return this.db.collection<any>(collectionName, ref =>
    ref.where('STORE_ID', '==', storeId)).get().toPromise();
  }
  // To get Events by stores
  getEventsFromFirestore(collectionName, storeId) {
    return this.db.collection<any>(collectionName, ref =>
    ref.where('STORE_ID', '==', storeId)).get().toPromise();
  }
  // To get cartProduct by userDocId
  getCartproductsFromFirestore(collectionName, userId) {
    return this.db.collection<any>(collectionName, ref =>
    ref.where('USER_DOC_ID', '==', userId)).get().toPromise();
  }
  addDataToFirestore(collectionName, data) {
    return this.db.collection(collectionName).add(data);
  }

  setResetPasscodeForPhone(collectionName, id , data) {
    return this.db.collection(collectionName).doc(id).update(data);
  }

  setDataToFirestore(collectionName, data, uid) {
    return this.db.collection(collectionName).doc(uid).set(data);
  }
  setSubCollectionDataToFirestore(collectionName, uid, subCollection, data) {
    return this.db.collection(collectionName).doc(uid).collection(subCollection).add(data);
  }
  sendMailToUsers(userName, email, password) {
    let sendUrl = URL +  "/api/sendEmailToUsers?email=" + email + "&password=" + password + "&userName=" + userName;
    return this.httpClient.get(sendUrl).toPromise().catch(e => {
      console.log(e)
    });
  }

  sendSMSToUser(userName, phoneNo, password) {
    let sendUrl = URL + "/api/sendSMSToUser?phoneNo=" + phoneNo + "&userName=" + userName + "&password=" + password;
    return this.httpClient.get(sendUrl).toPromise();
  }

  sendMailToStoreAdmins(email) {
    let sendUrl = URL +  "/api/sendEmailToUsers?email=" + email + "New Order is arrived";
    return this.httpClient.get(sendUrl).toPromise();
  }
  importStoreClientsToFireStore(contactArr) {
    let sendUrl = URL + "/api/importClientsToFireStore";
    return this.httpClient.post(sendUrl, contactArr).toPromise();
  }
  createEmailAndPassInFirestore(userId, password) {
    return new Promise(function(resolve, reject) {
      firebase.auth().createUserWithEmailAndPassword(userId, password).then((res) => {
        console.log(res);
        resolve(res);
      }).catch(function (error) {
        reject(error);
      });
    });
  }
  logIn(userId, password) {
    return new Promise(function(resolve, reject) {
      firebase.auth().signInWithEmailAndPassword(userId, password).then((res) => {
        console.log(res);
        resolve(res);
      }).catch(function (error) {
        reject(error);
      });
    });
  }
  logOut() {
    return new Promise(function(resolve, reject) {
      firebase.auth().signOut().then((res: any) => {
        resolve(res);
      }).catch(function (error) {
        reject(error);
      });
    });
  }
  resetPwd(password) {
    return new Promise(function(resolve, reject) {
      console.log(firebase.auth().currentUser);
      firebase.auth().currentUser.updatePassword(password)
      .then((res: any) => {
        resolve(res);
      }).catch(function (error) {
        console.log(error);
        reject(error);
      });
    });
  }
  sendPwdLinkToUser(email) {
    return new Promise(function(resolve, reject) {
      firebase.auth().sendPasswordResetEmail(email)
      .then((res: any) => {
        resolve(res);
      }).catch(function (error) {
        console.log(error);
        reject(error);
      });
    });
  }
  getCurrentUser() {
    return firebase.auth().currentUser;
  }
  updateDataInFirestore(collection, id, data) {
    return this.db.collection(collection).doc(id).update(data);
  }
  deleteDataFromFirestore(collection, id) {
    return this.db.collection(collection).doc(id).delete();
  }
  deleteSubCollectionDataFromFirestore(collection, id, subCollection, subId) {
    return this.db.collection(collection).doc(id).collection(subCollection).doc(subId).delete();
  }
  deleteAuthUserFromFirestore(uid) {
    let sendUrl = URL + "/api/deleteAuthUser?uid=" + uid;
    return this.httpClient.get(sendUrl).toPromise();
  }
  uploadToStorage(folderName, imageURL: string)   {
    console.log(imageURL);
    const storageRef: AngularFireStorageReference = this.afStorage
    .ref(folderName);
    return storageRef.put(imageURL);
  }
  deleteFolderFromStorage(folderName) {
    return new Promise(function(resolve, reject) {
      var storageRef = firebase.storage().ref(folderName);
        storageRef.listAll().then(function(result) {
          if (result.items.length > 0) {
            result.items.forEach(function(imageRef) {
                storageRef.child(imageRef.name).delete().then(function() {
                  // File deleted successfully
                  console.log("deleted successfully");
                  resolve(true);
                }).catch(function(error) {
                  // Uh-oh, an error occurred!
                  console.log(error);
                  reject(error);
                });
            });
          } else {
            resolve(true);
          }
      }).catch(function(error) {
        // Handle any errors
        console.log(error);
        reject(error);
      });
    });
  }
  deleteImageFromStorage(folderName) {
    return new Promise(function(resolve, reject) {
      var storageRef = firebase.storage().ref(folderName);
        storageRef.listAll().then(function(result) {
          if (result.items.length > 0) {
            result.items.forEach(function(imageRef) {
              // And finally display them
              if (imageRef.fullPath.includes('.jpg') || imageRef.fullPath.includes('.png')) {
                storageRef.child(imageRef.name).delete().then(function() {
                  // File deleted successfully
                  console.log("deleted successfully");
                  resolve(true);
                }).catch(function(error) {
                  // Uh-oh, an error occurred!
                  console.log(error);
                  reject(error);
                });
              }
            });
          } else {
            resolve(true);
          }
      }).catch(function(error) {
        // Handle any errors
        console.log(error);
        reject(error);
      });
    });
  }
  deleteClientImageFromStorage(folderName, url) {
    console.log(url);
    return new Promise(function(resolve, reject) {
      var storageRef = firebase.storage().ref(folderName);
        storageRef.listAll().then(function(result) {
          if (result.items.length > 0) {
            result.items.forEach(function(imageRef) {
              if (url.includes(imageRef.name)) {
                // And finally display them
                if (imageRef.fullPath.includes('.jpg') || imageRef.fullPath.includes('.png')) {
                  storageRef.child(imageRef.name).delete().then(function() {
                  // File deleted successfully
                  console.log("deleted successfully");
                  resolve(true);
                }).catch(function(error) {
                  // Uh-oh, an error occurred!
                  console.log(error);
                  reject(error);
                });
              }
            }
            });
          } else {
            resolve(true);
          }
      }).catch(function(error) {
        // Handle any errors
        console.log(error);
        reject(error);
      });
    });
  }
  getImageDownloadUrl(folderName)   {
    const storageRef: AngularFireStorageReference = this.afStorage
    .ref(folderName);
    return storageRef.getDownloadURL();
  }
  fetchSignInMethods(email) { 
    return this.fireAuth.auth.fetchSignInMethodsForEmail(email);
  }
  getAllStores() {
    return this.getPromiseDataFromFirestore('stores').then(snapshot => {
        let stores = [];
        snapshot.forEach(doc => {
          stores.push(
            {
              id: doc.id,
              STORE_NAME: doc.data().STORE_NAME,
              TENANT_ID: doc.data().TENANT_ID,
              FIREBASE_FILE_LOCATION: doc.data().FIREBASE_FILE_LOCATION,
              STORE_IMG: doc.data().STORE_IMG,
              SUBSCRIPTION_LEVEL: doc.data().SUBSCRIPTION_LEVEL,
              ORDER_EMAIL: doc.data().ORDER_EMAIL
            }
          );
        });
        return stores;
    });
  }
  getAllClients() {
    return this.getPromiseDataFromFirestore('clients').then(snapshot => {
        let clients = [];
        snapshot.forEach(doc => {
          clients.push(
            {
              id: doc.id,
              CLIENT_NAME: doc.data().CLIENT_NAME,
              CLIENT_ID: doc.data().CLIENT_ID,
              CLIENT_IMG: doc.data().CLIENT_IMG,
              STORE_ID: doc.data().STORE_ID
            }
          );
        });
        return clients;
    });
  }
  getAllEvents() {
    return this.getPromiseDataFromFirestore('events').then(snapshot => {
        let events = [];
        snapshot.forEach(doc => {
          events.push(
            {
              id: doc.id,
              DESCRIPTION: doc.data().DESCRIPTION,
              EVENT_DATE: doc.data().EVENT_DATE,
              EVENT_IMG: doc.data().EVENT_IMG,
              EVENT_TITLE: doc.data().EVENT_TITLE
            }
          );
        });
        return events;
    });
  }
  getSearchCustomers(value) {
    return this.db.collection('clients', ref => ref.where('CLIENT_NAME', '>=', value).where('CLIENT_NAME', '<=', value + '\uf8ff')).get().toPromise();
  }
}
