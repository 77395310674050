import { Injectable } from '@angular/core';
import { FirebaseService } from '../services/firebase.service';

@Injectable({
  providedIn: 'root'
})
export class GetFireBaseDataService {

  constructor(
    private firebaseService: FirebaseService
  ) { }

  async getAllStoresAdmins(storeAdminConst, storeId) {
    let storeAdmin = [];
    await this.firebaseService.getUserTypeDataFromFirestore('users', storeAdminConst, storeId).then(snapshot => {
      snapshot.forEach(doc => {
        let data = doc.data();
        storeAdmin.push(
          {
            id: doc.id,
            ADMIN_NAME: data.ADMIN_NAME,
            STORE_NAME: data.STORE_NAME,
            STORE_ID: data.STORE_ID,
            USER_NAME: data.USER_NAME,
            USER_TYPE: data.USER_TYPE,
            EMAIL_ID: data.EMAIL_ID,
            PASS_CODE: data.PASS_CODE,
            PHONE_NO: data.PHONE_NO,
            COUNTRY_CODE: data.COUNTRY_CODE
          }
        );
      });
    });
    return storeAdmin;
  }

  async getAllStoresUsers(storeUserConst, storeId) {
    let storeUsers = [];
    await this.firebaseService.getUserTypeDataFromFirestore('users', storeUserConst, storeId).then(snapshot => {
      snapshot.forEach(doc => {
        let data = doc.data()
        storeUsers.push(
          {
            id: doc.id,
            STORE_NAME: data.STORE_NAME,
            STORE_ID: data.STORE_ID,
            USER_NAME: data.USER_NAME,
            USER_TYPE: data.USER_TYPE,
            EMAIL_ID: data.EMAIL_ID,
            PASS_CODE: data.PASS_CODE,
            PHONE_NO: data.PHONE_NO,
            COUNTRY_CODE: data.COUNTRY_CODE
          }
        );
      });
    });
    return storeUsers;
  }

  async getAllCustomerAdmins(customerAdminConst, storeId, clientId) {
    let custAdmin = [];
    let snapshot: any;
    if(clientId)
      snapshot = await this.firebaseService.getUserTypeByClientIdDataFromFirestore('users', customerAdminConst, storeId, clientId);
    else 
      snapshot = await this.firebaseService.getUserTypeDataFromFirestore('users', customerAdminConst, storeId);

    snapshot.forEach(doc => {
      let custAdminData = doc.data();
      custAdmin.push(
        {
          id: doc.id,
          ADMIN_NAME: custAdminData.ADMIN_NAME,
          CUSTOMER_NO: custAdminData.CUSTOMER_NO,
          STORE_NAME: custAdminData.STORE_NAME,
          STORE_ID: custAdminData.STORE_ID,
          USER_NAME: custAdminData.USER_NAME,
          USER_TYPE: custAdminData.USER_TYPE,
          EMAIL_ID: custAdminData.EMAIL_ID,
          PASS_CODE: custAdminData.PASS_CODE,
          PHONE_NO: custAdminData.PHONE_NO,
          COUNTRY_CODE: custAdminData.COUNTRY_CODE
        }
      );
    });
    return custAdmin;
  }

  async getAllCustomerUsers(customerUserConst, storeId, clientId) {
    let custUsers = [];
    let snapshot;

    if(clientId)
      snapshot = await this.firebaseService.getUserTypeByClientIdDataFromFirestore('users', customerUserConst, storeId, clientId);
    else 
      snapshot = await this.firebaseService.getUserTypeDataFromFirestore('users', customerUserConst, storeId);

      snapshot.forEach(doc => {
        let custUserData = doc.data();
        custUsers.push(
          {
            id: doc.id,
            ADMIN_NAME: custUserData.ADMIN_NAME,
            CUSTOMER_NO: custUserData.CUSTOMER_NO,
            STORE_NAME: custUserData.STORE_NAME,
            STORE_ID: custUserData.STORE_ID,
            USER_NAME: custUserData.USER_NAME,
            USER_TYPE: custUserData.USER_TYPE,
            EMAIL_ID: custUserData.EMAIL_ID,
            PASS_CODE: custUserData.PASS_CODE,
            PHONE_NO: custUserData.PHONE_NO,
            COUNTRY_CODE: custUserData.COUNTRY_CODE
          }
        );
      });
      return custUsers;
  }

  async getClientsByStoreId(storeId) {
    let clients = [];
    await this.firebaseService.getClientFromFirestore('clients', storeId).then(snapshot => {
      snapshot.forEach(doc => {
        clients.push(
          {
            id: doc.id,
            CLIENT_NAME: decodeURIComponent(escape(doc.data().CLIENT_NAME)),
            CLIENT_ID: doc.data().CLIENT_ID,
            CLIENT_IMG: doc.data().CLIENT_IMG,
            STORE_ID: doc.data().STORE_ID
          }
        );
      });
    });
    return clients;
  }

  async getCustomerAdminByStoreIdClientId(customerAdminConst, storeId, clientId) {
    let custAdmin = [];
    await this.firebaseService.getCustomerUserDataFromFirestore('users', customerAdminConst, storeId, clientId).then(snapshot => {
      snapshot.forEach(doc => {
        custAdmin.push(
          {
            id: doc.id,
            ADMIN_NAME: doc.data().ADMIN_NAME,
            CUSTOMER_NO: doc.data().CUSTOMER_NO,
            STORE_NAME: doc.data().STORE_NAME,
            STORE_ID: doc.data().STORE_ID,
            USER_NAME: doc.data().USER_NAME,
            USER_TYPE: doc.data().USER_TYPE,
            EMAIL_ID: doc.data().EMAIL_ID,
            PASS_CODE: doc.data().PASS_CODE,
            PHONE_NO: doc.data().PHONE_NO,
            COUNTRY_CODE: doc.data().COUNTRY_CODE
          }
        );
      });
    });
    return custAdmin;
  }

  async getCustomerUsersByStoreIdClientId(customerUserConst, storeId, clientId) {
    let custUsers = [];
    await this.firebaseService.getCustomerUserDataFromFirestore('users', customerUserConst, storeId, clientId).then(snapshot => {
      snapshot.forEach(doc => {
        custUsers.push(
          {
            id: doc.id,
            ADMIN_NAME: doc.data().ADMIN_NAME,
            CUSTOMER_NO: doc.data().CUSTOMER_NO,
            STORE_NAME: doc.data().STORE_NAME,
            STORE_ID: doc.data().STORE_ID,
            USER_NAME: doc.data().USER_NAME,
            USER_TYPE: doc.data().USER_TYPE,
            EMAIL_ID: doc.data().EMAIL_ID,
            PASS_CODE: doc.data().PASS_CODE,
            PHONE_NO: doc.data().PHONE_NO,
            COUNTRY_CODE: doc.data().COUNTRY_CODE
          }
        );
      });
    });
    return custUsers;
  }

  async getAllStoreProducts(storeId) {
    let products = [];
    await this.firebaseService.getStoreSubCollection('stores', storeId, 'products').then(snapshot => {
      snapshot.forEach(doc => {
        products.push(
          {
            PRODUCT_DOC_ID: doc.id,
            DAY_PRICE: doc.data().DAY_PRICE,
            DEBIT_TYPE: doc.data().DEBIT_TYPE,
            DESCRIPTION: doc.data().DESCRIPTION,
            GROUND_PRICE: doc.data().GROUND_PRICE,
            IRRELEVANT_NO: doc.data().IRRELEVANT_NO,
            ITEM_NO: doc.data().ITEM_NO,
            ITEM_TYPE: doc.data().ITEM_TYPE,
            GROUP: doc.data().GROUP,
            GROUP_NO: doc.data().GROUP_NO,
            PRODUCT_DESCRIPTION: doc.data().PRODUCT_DESCRIPTION,
            PRODUCT_IMG: doc.data().PRODUCT_IMG,
            SUB_GROUP: doc.data().SUB_GROUP,
            SUB_GROUP_NO: doc.data().SUB_GROUP_NO,
          }
        );
      });
    });
    return products;
  }

  async getCartProductsByUserId(userId) {
    let cartProducts = [];
    await this.firebaseService.getCartproductsFromFirestore('cart', userId).then(snapshot => {
      snapshot.forEach(doc => {
        cartProducts.push(
          {
            id: doc.id,
            DAY_PRICE: doc.data().DAY_PRICE,
            DEBIT_TYPE: doc.data().DEBIT_TYPE,
            DESCRIPTION: doc.data().DESCRIPTION,
            GROUND_PRICE: doc.data().GROUND_PRICE,
            IRRELEVANT_NO: doc.data().IRRELEVANT_NO,
            ITEM_NO: doc.data().ITEM_NO,
            ITEM_TYPE: doc.data().ITEM_TYPE,
            NO_OF_PRODUCT: doc.data().NO_OF_PRODUCT,
            PRODUCT_DOC_ID: doc.data().PRODUCT_DOC_ID,
            USER_DOC_ID: doc.data().USER_DOC_ID,
            GROUP: doc.data().GROUP,
            GROUP_NO: doc.data().GROUP_NO,
            PRODUCT_DESCRIPTION: doc.data().PRODUCT_DESCRIPTION,
            PRODUCT_IMG: doc.data().PRODUCT_IMG,
            SUB_GROUP: doc.data().SUB_GROUP,
            SUB_GROUP_NO: doc.data().SUB_GROUP_NO,
            RENT_DATE_FROM: doc.data().RENT_DATE_FROM,
            RENT_DATE_TO: doc.data().RENT_DATE_TO
          }
        );
      });
    });
    return cartProducts;
  }

  async getAllEventsByStoreId(storeId) {
    let events = [];
    await this.firebaseService.getEventsFromFirestore('events', storeId).then(snapshot => {
      snapshot.forEach(doc => {
        events.push(
          {
            id: doc.id,
            DESCRIPTION: doc.data().DESCRIPTION,
            EVENT_DATE: doc.data().EVENT_DATE,
            EVENT_IMG: doc.data().EVENT_IMG,
            EVENT_TITLE: doc.data().EVENT_TITLE,
            STORE_ID: doc.data().STORE_ID
          }
        );
      });
    });
    return events;
  }

  async getAllNotificationByUserId(userDocId) {
    let notifications = [];
    await this.firebaseService.getStoreSubCollection('users', userDocId, 'notification').then(snapshot => {
      snapshot.forEach(doc => {
        notifications.push(doc.data());
      });
    });
    return notifications;
  }

  async getAllCustomerUsersByStoreId(storeId) {
    let allCutomerUsers = [];
    await this.firebaseService.getStoreUserFromFirestore('users', storeId).then(snapshot => {
      snapshot.forEach(doc => {
        let allCutomerUserData = doc.data();
        allCutomerUsers.push(
          {
            id: doc.id,
            ADMIN_NAME: allCutomerUserData.ADMIN_NAME,
            CLIENT_DOC_ID: allCutomerUserData.CLIENT_DOC_ID,
            CLIENT_ID: allCutomerUserData.CLIENT_ID,
            CLIENT_NAME: allCutomerUserData.CLIENT_NAME,
            CUSTOMER_NO: allCutomerUserData.CUSTOMER_NO,
            EMAIL_ID: allCutomerUserData.EMAIL_ID,
            NOTIFICATION_UNREAD_COUNT: allCutomerUserData.NOTIFICATION_UNREAD_COUNT,
            PHONE_NO: allCutomerUserData.PHONE_NO,
            COUNTRY_CODE: allCutomerUserData.COUNTRY_CODE,
            STORE_ID: allCutomerUserData.STORE_ID,
            STORE_NAME: allCutomerUserData.STORE_NAME,
            USER_NAME: allCutomerUserData.USER_NAME,
            USER_TYPE: allCutomerUserData.USER_TYPE
          }
        );
      });
    });
    return allCutomerUsers;
  }

  async getAllUsersPendingOrdersByStoreId(storeId) {
    let allPendingOrders = [];
    await this.firebaseService.getStoreSubCollection('stores', storeId, 'pending-orders').then(snapshot => {
      snapshot.forEach(doc => {
        allPendingOrders.push(
          {
            id: doc.id,
            CUSTOMER_NAME: doc.data().CUSTOMER_NAME,
            CUSTOMER_NO: doc.data().CUSTOMER_NO,
            DAY_PRICE: doc.data().DAY_PRICE,
            DESCRIPTION: doc.data().DESCRIPTION,
            GROUND_PRICE: doc.data().GROUND_PRICE,
            IRRELEVANT_NO: doc.data().IRRELEVANT_NO,
            ITEM_NO: doc.data().ITEM_NO,
            ITEM_TYPE: doc.data().ITEM_TYPE,
            NO_OF_PRODUCT: doc.data().NO_OF_PRODUCT,
            PRODUCT_DOC_ID: doc.data().PRODUCT_DOC_ID,
            USER_DOC_ID: doc.data().USER_DOC_ID,
            USER_EMAIL_ID: doc.data().USER_EMAIL_ID,
            USER_NAME: doc.data().USER_NAME,
            USER_PHONE_NO: doc.data().USER_PHONE_NO,
            USER_COUNTRY_CODE: doc.data().USER_COUNTRY_CODE
          }
        );
      });
    });
    return allPendingOrders;
  }

  async getAllSearchCustomers(value) {
    let searchCustomers = [];
    this.firebaseService.getSearchCustomers(value).then(snapshot => {
      snapshot.forEach(doc => {
        searchCustomers.push(
          {
            id: doc.id,
            CLIENT_NAME: decodeURIComponent(escape(doc.data().CLIENT_NAME)),
            CLIENT_ID: doc.data().CLIENT_ID,
            CLIENT_IMG: doc.data().CLIENT_IMG,
            STORE_ID: doc.data().STORE_ID
          }
        );
      });
    });
    return searchCustomers;
  }

  async getAllStoreHistory(storeId) {
    let history = [];
    await this.firebaseService.getStoreSubCollection('stores', storeId, 'Historik').then(snapshot => {
      snapshot.forEach(doc => {
        history.push(doc.data());
      });
    });
    return history;
  }

  async getAllStoreOrders(storeId) {
    let orders = [];
    await this.firebaseService.getStoreSubCollection('stores', storeId, 'Order').then(snapshot => {
      snapshot.forEach(doc => {
        orders.push(doc.data());
      });
    });
    return orders;
  }

}
