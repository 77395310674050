import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ObservableService {
  latestProduct: any;
  private productSubject$ = new BehaviorSubject<any>(this.latestProduct);
  productChanged$ = this.productSubject$.asObservable();
  constructor() { }

  setProduct(product: any) {
    this.productSubject$.next({ product: product });
  }

  getProduct(): Observable<any> {
    return this.productSubject$.asObservable();
  }
}
