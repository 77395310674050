import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Events, Platform } from '@ionic/angular';
import { GlobalService } from './services/global.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: [
    './side-menu/styles/side-menu.scss',
    './side-menu/styles/side-menu.shell.scss',
    './side-menu/styles/side-menu.responsive.scss'
  ]
})
export class AppComponent implements OnInit {

  constructor(
    private events: Events,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router,
    private globalService: GlobalService,
  ) {
    this.initializeApp();
  }
  initializeApp() {
    this.platform.ready().then(async () => {
    const session = await this.globalService.getLoginUser();
    if (!session || Object.keys(session).length === 0) {
      this.router.navigate(['/auth/login']);
    }
    this.statusBar.styleDefault();
    this.splashScreen.hide();
    });
  }
  ngOnInit() {
  }
}
