import { Component, OnInit } from '@angular/core';
import { FirebaseService } from '../../services/firebase.service';
import { GlobalService } from '../../services/global.service';
import { StoreServiceService } from '../../services/store-service.service';
import { Events, Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { SubscriptionLevelConst } from '../../constants/UserTypeConstants';
import { UserTypeConstants } from '../../constants/UserTypeConstants';
import { TranslationService } from '../../services/translation.service';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: [
    '../../side-menu/styles/side-menu.scss',
    '../../side-menu/styles/side-menu.shell.scss',
    '../../side-menu/styles/side-menu.responsive.scss'
  ]
})
export class SidemenuComponent implements OnInit {

  /* Displays user email */
  public userEmail;
  /* Displays dashboard tiles */
  dashboardTiles: any = [];
  /* Login userData */
  public userData: any = [];
  /* Login user Doc_id */
  public userId: any;
  /* Displays current user type */
  public currentUserType: string;
  /* Handles user type translation */
  public currentUserTypeTranslate: string;
  /* Displays current store name */
  public currentStoreName: string;
  /* Displays current user name */
  public currentUserName: string;
  /* Displays Super Admin as constant string  */
  public superAdminConst: string;
  /* Displays Store Admin as constant string  */
  public storeAdminConst: string;
  /* Displays Store Users as constant string  */
  public storeUsersConst: string;
  /* Displays Customer Admin as constant string  */
  public customerAdminConst: string;
  /* Displays Customer users as constant string  */
  public customerUserConst: string;
  /* Displays selected language */
  selectedLanguage: any;
  /* Notification Count */
  public notificationCount: any;
  /* Display store id */
  public storeId: any;
  /* user store Subscription Level */
  public storeSubscriptionLevel: string;

  constructor(
    private events: Events,
    private firebaseService: FirebaseService,
    private globalService: GlobalService,
    private storeService: StoreServiceService,
    private router: Router,
    private translationService: TranslationService,
    public platform: Platform,
    public inappbrowser: InAppBrowser
  ) {
    this.superAdminConst = UserTypeConstants.SUPER_ADMIN;
    this.storeAdminConst = UserTypeConstants.STORE_ADMIN;
    this.storeUsersConst = UserTypeConstants.STORE_USER;
    this.customerAdminConst = UserTypeConstants.CUSTOMER_ADMIN;
    this.customerUserConst = UserTypeConstants.CUSTOMER_USER;
    this.selectedLanguage = this.translationService.getDefaultLanguage();
    if (this.selectedLanguage == null) {
      localStorage.setItem('LANGUAGE', 'english');
      this.translationService.setLanguage(('english'));
    } else {
      this.translationService.setLanguage((this.selectedLanguage));
    }
    this.initLoginUser();
    events.subscribe('activateMenu', (dashboardTile) => {
      let selectedTile = this.dashboardTiles.filter(tile => {
        return tile.title == dashboardTile;
      });
      this.activateMenu(selectedTile[0]);
    });
  }
  activateMenu(dashboardTile) {
    this.dashboardTiles.forEach(tile => {
      if (tile.isClicked === true) {
        tile.isClicked = false;
      }
    });
    if (!!dashboardTile) {
    dashboardTile.isClicked = true;
  }
  }
  navigateToAccordingPage(menu) {
    if (menu.title === 'ADMIN.LOG_OUT') {
      this.firebaseService.logOut().then(response => {
        this.globalService.removeStorage('loginUser').then(() => {
          this.router.navigate([menu.url]);
        });
        // this.events.unsubscribe('loginUser');
      });
    } else if (menu.title === 'ADMIN.NEWS') {
      this.navigateToNews();
    }else {
      this.router.navigate([menu.url]);
    }
  }

   /* Navigates to News */
   navigateToNews() {
    if (this.platform.is("android")) {
      window.open('https://www.instagram.com/inbyrental/');
    } else if (this.platform.is("ios")) {
      this.inappbrowser.create('https://www.instagram.com/inbyrental/', "_blank");
    } else {
      window.open('https://www.instagram.com/inbyrental/');
    }
}
  async getUserStoreProfile(storeId) {
    let storeAllData = await this.storeService.getStoreDataById(storeId);
    this.storeSubscriptionLevel = storeAllData.SUBSCRIPTION_LEVEL;
  }

  ngOnInit() {
  }

  initLoginUser() {
    let loginUser = this.globalService.getLoginUser().then((loginUser: any) => {
      if (!loginUser) {
        console.log('no login user found');
        return;
      }
      this.currentUserType = loginUser.USER_TYPE;
      this.notificationCount = loginUser.NOTIFICATION_UNREAD_COUNT;
      this.currentUserName = loginUser.USER_NAME;
      this.userId = loginUser.id;
      this.currentStoreName = loginUser.STORE_NAME;
      if (this.currentUserType == this.superAdminConst) {
        this.currentUserTypeTranslate = "ADMIN.SUPER_ADMIN";
      } else if (this.currentUserType == this.storeAdminConst) {
        this.currentUserTypeTranslate = "ADMIN.STORE_ADMIN";
      } else if (this.currentUserType == this.storeUsersConst) {
        this.currentUserTypeTranslate = "ADMIN.STORE_USER";
      } else if (this.currentUserType == this.customerAdminConst) {
        this.currentUserTypeTranslate = "ADMIN.CUSTOMER_ADMIN";
      } else if (this.currentUserType == this.customerUserConst) {
        this.currentUserTypeTranslate = "ADMIN.CUSTOMER_USER";
      }
      this.userEmail = loginUser.EMAIL_ID;
      this.dashboardTiles = [
        {
          title: 'ADMIN.DASHBOARD',
          url: '/app/dashboard',
          icon: './assets/sample-icons/side-menu/categories.svg',
          isTrue: true,
          isClicked: true
        },
        {
          title: 'ADMIN.STORES',
          url: '/app/dashboard/store-tenants',
          icon: './assets/sample-icons/side-menu/store.svg',
          isTrue: this.currentUserType === this.superAdminConst ? true : false,
          isClicked: false
        },
        {
          title: 'ADMIN.CUSTOMERS',
          url: '/app/dashboard/clients',
          icon: './assets/sample-icons/side-menu/clients.svg',
          isTrue: (this.currentUserType === this.superAdminConst || this.currentUserType == this.storeAdminConst || this.currentUserType == this.storeUsersConst) ? true : false,
          isClicked: false
        },
        {
          title: 'ADMIN.STORE_ADMINS',
          url: '/app/dashboard/store-admins-list',
          icon: './assets/sample-icons/side-menu/store-admins.svg',
          isTrue: this.currentUserType === this.storeAdminConst ? true : false,
          isClicked: false
        },
        {
          title: 'ADMIN.STORE_USERS',
          url: '/app/dashboard/store-users-list',
          icon: './assets/sample-icons/side-menu/store-admins.svg',
          isTrue: (this.currentUserType != this.customerAdminConst && this.currentUserType != this.customerUserConst && this.currentUserType != this.superAdminConst && this.currentUserType != this.storeUsersConst) ? true : false,
          isClicked: false
        },
        {
          title: 'ADMIN.PREVIOUS_RENTALS',
          url: '/app/dashboard/history',
          icon: './assets/sample-icons/side-menu/orders.svg',
          isTrue: true,
          isClicked: false
        },
        {
          title: 'ADMIN.CURRENT_RENTALS',
          url: '/app/dashboard/orders',
          icon: './assets/sample-icons/side-menu/history.svg',
          isTrue: true,
          isClicked: false
        },
        {
          title: 'ADMIN.PRODUCTS',
          url: '/app/dashboard/store-des',
          icon: './assets/sample-icons/side-menu/cart.svg',
          isTrue: (this.currentUserType == this.superAdminConst || this.storeSubscriptionLevel == SubscriptionLevelConst.LEVEL_2 || this.storeSubscriptionLevel == SubscriptionLevelConst.LEVEL_4),
          isClicked: false
        },
        {
          title: 'ADMIN.ORDER',
          url: '/cart-des',
          icon: './assets/sample-icons/side-menu/products.svg',
          isTrue: (this.currentUserType == this.superAdminConst || this.storeSubscriptionLevel == SubscriptionLevelConst.LEVEL_2 || this.storeSubscriptionLevel == SubscriptionLevelConst.LEVEL_4),
          isClicked: false
        },
        {
          title: 'ADMIN.NEWS',
          url: '/app/dashboard/events-news',
          icon: './assets/sample-icons/side-menu/event-news.svg',
          isTrue: true,
          isClicked: false
        },
        {
          title: 'ADMIN.PROFILE',
          url: '/profile',
          icon: './assets/sample-icons/side-menu/profile.svg',
          isTrue: true,
          isClicked: false
        },
        {
          title: 'ADMIN.NOTIFICATIONS',
          url: '/app/dashboard/notifications',
          icon: './assets/sample-icons/side-menu/notifications.svg',
          isTrue: true,
          isClicked: false,
          notificationCount: this.notificationCount
        },
        {
          title: 'ADMIN.CUSTOMER_ADMINS',
          url: '/app/dashboard/customer-admin-list',
          icon: './assets/sample-icons/side-menu/customer-admins.svg',
          isTrue: (this.currentUserType === this.customerAdminConst) ? true : false,
          isClicked: false
        },
        {
          title: 'ADMIN.CUSTOMER_USERS',
          url: '/app/dashboard/customer-user-list',
          icon: './assets/sample-icons/side-menu/customer-users.svg',
          isTrue: (this.currentUserType != this.customerUserConst && this.currentUserType != this.superAdminConst && this.currentUserType != this.storeUsersConst && this.currentUserType != this.storeAdminConst) ? true : false,
          isClicked: false
        },
        {
          title: 'ADMIN.SETTINGS',
          url: '/settings',
          icon: './assets/sample-icons/side-menu/settings.svg',
          isTrue: true,
          isClicked: false
        },
        {
          title: 'ADMIN.LOG_OUT',
          url: '/auth/login',
          icon: './assets/sample-icons/side-menu/login.svg',
          isTrue: true,
          isClicked: false
        }
      ];
      if (this.currentUserType != this.superAdminConst) {
        this.storeId = loginUser.STORE_ID;
        this.getUserStoreProfile(this.storeId).then(() => {
          if (this.storeSubscriptionLevel === SubscriptionLevelConst.LEVEL_2 || this.storeSubscriptionLevel === SubscriptionLevelConst.LEVEL_4) {
            let productTile = this.dashboardTiles.find(tile => tile.title === 'ADMIN.PRODUCTS');
            let productTileIndex = this.dashboardTiles.findIndex(tile => tile.title === 'ADMIN.PRODUCTS');
            productTile.isTrue = this.currentUserType == this.superAdminConst || this.storeSubscriptionLevel === SubscriptionLevelConst.LEVEL_2 || this.storeSubscriptionLevel === SubscriptionLevelConst.LEVEL_4;
            this.dashboardTiles[productTileIndex] = productTile;
            let orderTile = this.dashboardTiles.find(tile => tile.title === 'ADMIN.ORDER');
            let orderTileIndex = this.dashboardTiles.findIndex(tile => tile.title === 'ADMIN.ORDER');
            orderTile.isTrue = this.currentUserType == this.superAdminConst || this.storeSubscriptionLevel === SubscriptionLevelConst.LEVEL_2 || this.storeSubscriptionLevel === SubscriptionLevelConst.LEVEL_4;
            this.dashboardTiles[orderTileIndex] = orderTile;
          }
        });
      }
    });
  }
 }
