import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertController, ModalController, Events } from '@ionic/angular';
import { DetailsPage } from '../../modals/details/details.page';
import { GetterSetterService } from '../../services/getter-setter.service';
import { Router } from '@angular/router';
import { FirebaseService } from '../../services/firebase.service';
import { ToastService } from '../../services/toast.service';
import { GlobalService } from '../../services/global.service';
import { ObservableService } from '../../services/observable-service.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {
  /* Display Edit button on list */
  @Input() isEdit: boolean = false;
  /* Display Users button on list */
  @Input() isUsers: boolean = false;
  /* Display Delete button on list */
  @Input() isDelete: boolean = false;
  /* Display store user label on list */
  @Input() isStoreUser: boolean = false;
  /* Display store Admin label on list */
  @Input() isStoreAdmin: boolean = false;
  /* Display Customer Admin label on list */
  @Input() isCustAdmin: boolean = false;
  /* Display Customer User label on list */
  @Input() isCustUser: boolean = false;
  /* Display Detail page on click */
  @Input() isView: boolean = false;
  /* Display Client label on list */
  @Input() isCustomers: boolean = false;
  /* Display listData */
  @Input() componentData: [];
  /* Display filteredData */
  @Input() filteredIdArray: any = [];
  /* Display icon on list */
  @Input() isIcon: boolean = false;
  /* Display Page Name */
  @Input() pageName: string;
  /* Display items */
  @Input() item: any;
  /* Display title */
  @Input() title: string;
  /* Display sub title */
  @Input() subTitle: string;
  /* Get image Url */
  @Input() imgUrl: string;
  /* Display header text  */
  @Input() headingText: string;
  /* Use for edit on page */
  @Output() onEdit = new EventEmitter();
  /* Use for displaying users on page */
  @Output() onUsers = new EventEmitter();
  /* Use for delete on page */
  @Output() onDelete = new EventEmitter();
  /* Use for view details on page */
  @Output() onView = new EventEmitter();
  /* Use for refresh on page after modal closed */
  @Output() onRefresh = new EventEmitter();
  /* Use for displaying clients on page */
  @Output() onClients = new EventEmitter();
  /* CurrentProductDetails */
  public currentProductDetails: any = [];
  /* Loader */
  public loading: boolean;
  /* Login user details */
  public logInUserDetails: any;
  /* Login user cart product */
  public cartProducts: any = [];
  /* Cart Doc_Id */
  public cartId: any;
  /* Cart Data */
  public cartProductData: any;
  /* Display details of product */
  @Input() isDetail: boolean = false;

  constructor(
    public alertController: AlertController,
    private modalController: ModalController,
    public router: Router,
    private events: Events,
    private globalService: GlobalService,
    private firebaseService: FirebaseService,
    private toastService: ToastService,
    private getterSetterService: GetterSetterService,
    private observableService: ObservableService) { }

  ngOnInit() {
  }
  /* Perform edit operation on page */
  onEditEmit() { this.onEdit.emit(); }
  /* Displayed users on page */
  onUsersEmit() { this.onUsers.emit(); }
  /* Perform delete operation on page */
  onDeleteEmit() { this.onDelete.emit(); }
  /* Perform Clients operation on page */
  onCustomersEmit() { this.onClients.emit(); }
  /* Open detail modal which displays the detailed info */
  async goToView(itemDetails) {
    if (this.isView === true) {
      this.onView.emit(itemDetails);
    } else {
      this.getterSetterService.setTenantItemData(itemDetails);
    }
    const modal = await this.modalController.create({
      component: DetailsPage,
      componentProps: { pageName: this.pageName, isEdit: this.isEdit, isDelete: this.isDelete, isDetail: this.isDetail },
      cssClass: 'my-card-approved-modal-css'
    });
    modal.present();
    modal.onDidDismiss().then((resp: any) => {
      if (resp.data.reLoad == false) {
        this.onRefresh.emit({ reLoad: resp.data.reLoad, storeId: resp.data.storeId });
      }
    });
  }
  productDetails(data) {
    this.observableService.setProduct(data);
    this.router.navigate(['/product-configuration']);
  }
}
