import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
@Injectable({
  providedIn: 'root'
})
export class AlertService {

  constructor(public alertController: AlertController, private translate: TranslateService) { }
  /* Alert with two buttons */
  presentAlert(alertMessage) {
    return new Promise((resolve: any, reject: any) => {
      this.alertController.create({
        header: this.translateMessage('POPOVERS.ALERT'),
        message: this.translateMessage(alertMessage),
        backdropDismiss: false ,
        buttons: [
          { text: this.translateMessage('POPOVERS.YES'), handler: resolve },
         { text: this.translateMessage('POPOVERS.NO'), role: this.translateMessage('Cancel'), handler: reject }
        ]
      }).then(alert => alert.present());
    });
  }
  /* Alert for missing fields with two different messages */
  async  presentSingleBtnAlert(alertMessage, header?) {
    const alert = await this.alertController.create({
      header: this.translateMessage(header ? header : 'POPOVERS.MISSING_FIELDS'),
      message: this.translateMessage(alertMessage),
      buttons: [this.translateMessage('POPOVERS.OK')]
    });
    await alert.present();
  }
   /* Alert for present status alert messages */
   async  presentStatusBtnAlert(alertMessage) {
    const alert = await this.alertController.create({
      message: this.translateMessage(alertMessage),
      buttons: [this.translateMessage('POPOVERS.OK')]
    });
    await alert.present();
  }
  /* Alert with single button */
  async  singleBtnAlert(alertHeader, alertMessage) {
    const alert = await this.alertController.create({
       header: this.translateMessage(alertHeader),
       message: this.translateMessage(alertMessage),
       buttons: [this.translateMessage('POPOVERS.OK')]
    });
    await alert.present();
  }
  /* Translates the alert message */
  translateMessage(message) {
    let alertHeader: string;
    this.translate.get(message).subscribe((res: string) => {
      alertHeader = res;
    });
    return alertHeader;
  }
}
